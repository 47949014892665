import React from 'react';
import {Sequence, useVideoConfig, AbsoluteFill} from 'remotion';
import sampleInput from '../public/sample-input.json';
import '../styles/templates.css';
import '../styles/colors.css';
import '../styles/globals.css';
import '../styles/fonts.css';

const TextChunk = ({chunk, fontStyles}) => {
	console.log(fontStyles);
	let allTexts = [];
	let allEmojis = [];
	chunk.rows.forEach((row) => {
		row.widgets.forEach((widget) => {
			if (widget.text) {
				allTexts.push(widget.text);
			}
			if (widget.emoji) {
				allEmojis.push(widget.emoji);
			}
		});
	});
	const sentence = allTexts.join(' ');

	const textStyle = {
		fontFamily: fontStyles.fontFamily,
		fontSize: `70px`,
		textTransform: fontStyles.fontUppercase ? 'uppercase' : 'none',
		fontWeight: fontStyles.fontWeight,
		marginTop: '20px',
		marginBottom: '20px',
	};

	return (
		<div className={`animation-test ${chunk.classes}`}>
			<div className="row-container sb-text-shadow-lg highlight-mode computedHighlightLineStyle important row">
				<div className="row">
					<h1 style={textStyle}>{sentence}</h1>
					<div className="emojis">
						{allEmojis.map((emoji, index) => (
							<span key={index} style={{fontSize: '70px'}}>
								{emoji}
							</span>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

const TextOverlay = () => {
	const {fps} = useVideoConfig();
	const inputData = sampleInput['0'].json.inputProps;
	console.log(sampleInput['0'].json.project.config.style);
	const fontStyles = sampleInput['0'].json.project.config.style;

	return (
		<AbsoluteFill>
			{inputData.chunks.map((chunk, index) => {
				const startFrame = chunk.start_time * fps;
				const endFrame = chunk.end_time * fps;
				const duration = endFrame - startFrame;

				return (
					<Sequence
						key={index}
						from={startFrame}
						durationInFrames={duration}
						style={{justifyContent: 'center'}}
					>
						<TextChunk chunk={chunk} fontStyles={fontStyles} />
					</Sequence>
				);
			})}
		</AbsoluteFill>
	);
};

export default TextOverlay;
