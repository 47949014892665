import {Video} from 'remotion';
import TextOverlay from './TextOverlay';
import {AbsoluteFill} from 'remotion';

export const MyVideo: React.FC = () => {
	return (
		<div>
			<Video src={require('../public/sample-input.mp4')} />
			<TextOverlay />
		</div>
	);
};
